'use strict';

const scripts = document.querySelectorAll('script[data-js-loader]');

if (scripts.length) {
  scripts.forEach(async function(item) {
    const url = item.getAttribute('data-js-loader');

    try {
      const rs = await fetch(url, { mode: 'no-cors', cache: 'no-cache' });

      if (rs.ok) {
        const src = await rs.text();

        eval.call(null, src);
      } else if (rs.type === 'opaque' && rs.body === null) {
        const script = document.createElement('script');

        script.setAttribute('type', 'text/javascript');
        script.setAttribute('src', url);
        script.setAttribute('async', true);

        document.body.appendChild(script);
      }
    } catch (e) {
      console.log(e);
    }
  });
}
